.employeeSpend-tooltipContainer {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    gap: 3px;
}

.employeeSpend-currencyDisplay {
    font-size: 14px;
    font-weight: bold;
    line-height: 15.43px;
}

.employeeSpend-volumeDisplay {
    color: #B4C8DB;
    font-size: 12px;
    line-height: 13.22px;
}