.address-autocomplete__suggestions {
  font-family: Arial, Helvetica, sans-serif;
  padding: 2;
  margin: 2;
}

.address::placeholder {
  color: rgb(140, 140, 140);
}

.address-autocomplete__suggestions__item:hover {
  font-weight: 400;
  background-color: rgb(245, 245, 245);
}
