/* CSS for adding a horizontal line above the "None" options on the account and card filters */
li[data-value="NO_PLAN"] {
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
}

li[data-value="NO_PLAN"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  border-top: 1px solid #ccc;
}

li[data-value="NONE"] {
  position: relative;
  padding-top: 10px;
  margin-top: 10px;
}

li[data-value="NONE"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  border-top: 1px solid #ccc;
}
